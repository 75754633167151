import React, {useEffect, useState} from 'react';
import cat from '../src/sportygentleman.gif';
import encourage from '../src/encourage.png';
import encourage2 from '../src/encourage2.png';
import './App.css';

function App() {
    const [state, setState] = useState({
        catVisible: false,
        encouragingImageVisible: false
    });

    useEffect(() => {
        setTimeout(() => {
            setState(state => ({...state, catVisible: true}) );
        }, 2000)

        console.log(state.encouragingImageVisible)

        setTimeout(() => {
            setState(state => ({...state, encouragingImageVisible: true}) );
        }, 3000)
    }, [state]);

    return (
        <div className="App">
            <header className="App-header">
                <div>
                    <h1 className={"truth"}>Yes, yes you should.</h1>
                    <img
                        className={"cat " + (state.catVisible ? 'fadeIn' : 'fadeOut')}
                        src={cat} alt="A sporting gentleman (a cat)" width={450}/>
                </div>
                <img
                    className={"encouraging-message-1 " + (state.encouragingImageVisible ? 'fadeIn' : 'fadeOut')}
                    src={encourage} alt="an encouraging message. (you could be gaining muscles!!)" width={525}/>
                <img
                    className={"encouraging-message-2 " + (state.encouragingImageVisible ? 'fadeIn' : 'fadeOut')}
                    src={encourage2} alt="an encouraging message. (you could be gaining muscles!!)" width={450}/>
            </header>
        </div>
    );
}

export default App;
